<template>
  <div>
    <popup
      v-if="dialog"
      @closePopup="closePopup"
      @createData="postAgeGroup($event)"
      @editData="editAgeGroup($event)"
      :dialog="dialog"
      :dialogData="dialogData"
      :type="popupType"
      :loader="ageGroup.postAgeState.loading"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteAgeGroup($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
    />
    <v-row class="mb-n12">
      <v-col>
        <age-filter
          @getData="setDataFilter($event)"
          @clearData="setDataFilter({})"
        />
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="createPopup"
          ><span style="color: white;">{{ $t("add") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="edit"
      :deleteable="deleteItem"
      :loader="loader"
      :meta="pagination"
    />
  </div>
</template>
<script>
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import ageFilter from "./filter.vue";
import popup from "@/pages/Dashboard/age-group/craete.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
import { ageGroup } from "@/store/exercise/age-group";
export default {
  components: {
    dataTable,
    popup,
    deletePopup,
    ageFilter,
  },
  data() {
    return {
      ageGroup,
      dialog: false,
      deleteDialog: false,
      dialogData: null,
      deleteDialogData: null,
      popupType: null,
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.filterData, { page: page });
      ageGroup.get(this.filterData);
      this.dialog = false;
    },
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    createPopup() {
      this.dialog = true;
      this.popupType = "create";
    },
    edit(obj) {
      this.dialog = true;
      this.popupType = "edit";
      this.dialogData = obj;
    },
    deleteItem(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    closePopup() {
      this.dialog = false;
    },
    postAgeGroup(data) {
      ageGroup.post(data);
      this.dialog = false;
    },
    editAgeGroup(data) {
      ageGroup.edit(data.data, data.id);
      this.dialog = false;
    },
    deleteAgeGroup(id) {
      ageGroup.delete(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    tableData() {
      return ageGroup.tableData;
    },
    loader() {
      return ageGroup.getState.loading;
    },
    pagination() {
      return ageGroup.tableData.meta;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
